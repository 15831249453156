// core
import React, {useRef, useState, useEffect} from 'react';
import {useWindowSize} from 'react-use';
import {useNavigate, useParams} from "react-router-dom";

// utils
import {clearStoragePlatformLink} from "../../utils/common";

// service
import {checkCustomLink} from "../../services/custom-link";

// components
import PageLoader from "../../components/atoms/PageLoader";
import HomeBgTop from "./HomeBgTop";
import HomeNav from "./HomeNav";
import HomeHead from "./HomeHead";
import HomeWorked from "./HomeWorked";
import HomePublications from "./HomePublications";
// import HomeStats from "./HomeStats";
import HomeGames from "./HomeGames";
import HomeCards from "./HomeCards";
import HomeHow from "./HomeHow";
import HomeCasino from "./HomeCasino";
import HomeStaking from "./HomeStaking";
import HomeProgram from "./HomeProgram";
import HomeFeatures from "./HomeFeatures";
import HomeRise from "./HomeRise";
import HomeFaq from "./HomeFaq";
import MainBottom from "../../components/images/MainBottom";

// styles
import './index.scss'

const Home = () => {
  const navigate = useNavigate();
  const {width} = useWindowSize();
  let {customLink} = useParams();

  const [isLoading, setLoading] = useState(true)

  const faqRef = useRef(null)

  const checkParamLink = (customLink) => {
    checkCustomLink(customLink)
      .then(res => {
        let exists = res.data.exists
        if (exists) {
          sessionStorage.setItem("customLinkAddress", exists.address);
        } else {
          clearStoragePlatformLink();
          navigate('/')
        }
      }).catch(error => {
      navigate('/')
    }).finally(()=>{
      setLoading(false)
    });
  };


  useEffect(() => {
    if (customLink !== undefined) {
      checkParamLink(customLink);
    } else {
      setTimeout(() => {
        clearStoragePlatformLink();
        setLoading(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // @ts-ignore
  return (
    <div className='home'>
      <PageLoader active={isLoading}/>

      {width > 768 && (<div className="home__bg"><HomeBgTop/></div>)}

      <HomeNav faqElRef={faqRef}/>

      <div className='app__wrapper'>
        <HomeHead/>
        <HomeWorked/>
        <HomePublications/>
        {/*<HomeStats/>*/}

      </div>
      <HomeCards/>

      <div className='app__wrapper'>
        <HomeHow/>
        <HomeCasino/>
        <HomeStaking/>
        <HomeProgram/>
      </div>

      <HomeFeatures/>

      <div className='app__wrapper'>
        <HomeRise/>
        <HomeGames/>
        <div className="home__faq" ref={faqRef}><HomeFaq/></div>
        <div className='home__bottom'>
          <MainBottom/>
        </div>
      </div>
    </div>
  );
};

export default Home;


