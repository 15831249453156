const Bloomberg = (props) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="920.000000pt" height="259.000000pt" viewBox="0 0 920.000000 259.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,259.000000) scale(0.100000,-0.100000)"
       fill="#ffffff" stroke="none">
      <path d="M1310 1350 l0 -620 105 0 105 0 0 620 0 620 -105 0 -105 0 0 -620z"/>
      <path d="M5090 1350 l0 -620 100 0 100 0 0 60 0 60 36 -40 c20 -22 61 -52 92
  -67 51 -25 68 -28 162 -28 98 0 109 2 171 32 110 54 192 160 218 281 45 214
-45 426 -217 508 -47 23 -74 29 -152 32 -115 5 -183 -16 -254 -79 l-46 -41 0
261 0 261 -105 0 -105 0 0 -620z m514 26 c105 -32 166 -119 166 -235 -1 -144
-96 -241 -238 -241 -154 0 -264 140 -232 294 29 143 165 224 304 182z"/>
      <path d="M280 1336 l0 -606 258 0 c281 0 347 8 453 56 146 66 213 226 164 390
  -26 87 -112 165 -210 188 l-29 7 40 18 c135 61 193 198 148 350 -21 71 -89
139 -170 172 -57 23 -70 24 -356 27 l-298 3 0 -605z m542 396 c56 -27 78 -65
78 -132 0 -47 -4 -56 -38 -90 -48 -48 -109 -62 -258 -58 l-109 3 0 145 c-1 80
1 149 4 154 2 5 66 6 141 4 113 -4 146 -9 182 -26z m53 -497 c51 -26 78 -72
79 -139 1 -67 -26 -113 -85 -146 -41 -23 -55 -25 -209 -28 l-165 -4 -1 172 -2
172 172 -4 c147 -3 177 -6 211 -23z"/>
      <path d="M8645 1904 c-70 -36 -115 -105 -115 -176 1 -128 124 -222 248 -189
137 37 189 213 94 314 -60 63 -160 86 -227 51z m174 -43 c138 -98 50 -321
-117 -296 -66 10 -127 71 -137 138 -21 137 142 238 254 158z"/>
      <path d="M8640 1730 l0 -110 31 0 c30 0 31 1 27 40 -3 33 0 40 14 40 10 0 26
  -16 38 -40 17 -33 26 -40 50 -40 17 0 30 2 30 6 0 3 -11 21 -25 42 -25 36 -25
36 -5 57 20 21 26 61 12 81 -15 23 -52 34 -111 34 l-61 0 0 -110z m125 50 c11
-18 -16 -41 -43 -38 -17 2 -26 10 -28 26 -3 19 1 22 30 22 19 0 38 -5 41 -10z"/>
      <path d="M1938 1546 c-116 -41 -194 -112 -244 -221 -53 -115 -50 -266 6 -382
128 -260 525 -314 729 -98 215 227 128 597 -166 701 -95 33 -230 33 -325 0z
m270 -185 c181 -92 172 -368 -15 -446 -53 -22 -148 -19 -200 6 -141 68 -175
273 -64 392 69 75 187 95 279 48z"/>
      <path d="M2938 1551 c-123 -40 -206 -113 -261 -229 -30 -63 -32 -74 -32 -182
0 -109 2 -119 32 -182 155 -329 662 -330 815 -2 24 52 32 83 36 152 11 197
-76 346 -246 425 -60 28 -79 31 -177 34 -87 2 -122 -1 -167 -16z m218 -176
c102 -30 166 -118 167 -229 1 -143 -97 -245 -236 -246 -141 0 -236 97 -237
241 0 114 61 203 162 234 57 18 84 18 144 0z"/>
      <path d="M3992 1553 c-43 -15 -93 -57 -124 -103 -17 -25 -17 -24 -17 40 l-1
65 -95 -3 -95 -3 0 -410 0 -409 100 0 100 0 0 238 c0 268 8 318 61 370 36 36
105 57 153 48 48 -9 93 -50 106 -94 5 -21 10 -151 10 -299 l0 -263 105 0 105
0 0 258 c0 228 3 262 19 297 61 136 253 138 291 4 6 -19 10 -152 10 -296 l0
-263 106 0 105 0 -3 303 c-5 360 -9 382 -92 463 -65 63 -114 78 -228 72 -100
-5 -148 -27 -205 -92 l-40 -45 -30 43 c-47 66 -116 96 -217 95 -45 0 -100 -7
-124 -16z"/>
      <path d="M6359 1547 c-138 -48 -242 -161 -273 -297 -19 -80 -20 -118 -6 -190
33 -172 167 -307 340 -340 106 -20 220 -6 308 39 40 21 132 98 132 111 0 9
-124 110 -135 110 -4 0 -23 -14 -43 -31 -60 -53 -114 -72 -197 -67 -94 4 -146
36 -181 111 -13 29 -24 58 -24 65 0 9 69 12 310 12 l310 0 0 78 c0 182 -77
325 -209 389 -48 23 -71 27 -161 30 -89 3 -115 0 -171 -20z m239 -151 c50 -26
84 -74 95 -133 l7 -33 -210 0 c-166 0 -210 3 -210 13 0 7 12 36 26 65 25 51
65 84 122 103 36 11 136 3 170 -15z"/>
      <path d="M7361 1544 c-35 -18 -64 -42 -85 -71 l-31 -45 -3 61 -3 61 -99 0
  -100 0 0 -410 0 -410 99 0 99 0 4 248 c3 230 4 250 24 287 12 22 30 47 40 56
52 45 132 65 207 51 l48 -10 -3 102 -3 101 -70 3 c-59 2 -78 -1 -124 -24z"/>
      <path d="M7831 1548 c-249 -84 -342 -448 -172 -673 128 -170 390 -205 554 -74
l37 30 0 -59 c0 -128 -56 -223 -147 -250 -57 -17 -174 -15 -230 4 -26 9 -74
34 -106 56 -33 22 -60 38 -61 36 -2 -2 -29 -38 -61 -81 -57 -76 -57 -78 -39
-98 29 -32 153 -88 234 -105 90 -19 258 -14 334 10 106 34 182 99 232 198 47
92 53 166 54 606 l0 402 -100 0 -100 0 0 -55 0 -54 -43 43 c-86 85 -244 111
-386 64z m302 -184 c89 -45 132 -128 125 -239 -8 -130 -101 -215 -234 -215
-45 0 -78 7 -109 21 -196 92 -173 388 33 446 56 15 141 9 185 -13z"/>
    </g>
  </svg>

);

export default Bloomberg;
