const MarketWatch = (props) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="900.000000pt" height="500.000000pt" viewBox="0 0 900.000000 500.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
       fill="#ffffff" stroke="none">
      <path d="M7056 3055 c-41 -22 -55 -44 -56 -88 0 -55 43 -97 99 -97 54 0 90 32
98 86 4 35 1 46 -23 73 -35 38 -78 47 -118 26z"/>
      <path d="M4290 2889 l0 -170 -29 20 c-92 66 -219 55 -313 -27 -110 -98 -122
-314 -22 -431 91 -106 221 -129 327 -57 l36 25 3 -27 c3 -26 5 -27 81 -30 l77
-3 0 436 0 435 -80 0 -80 0 0 -171z m-50 -277 c59 -45 81 -143 45 -212 -49
-95 -210 -92 -251 5 -29 69 -14 155 34 198 44 40 124 44 172 9z"/>
      <path d="M4604 3026 c-24 -24 -34 -43 -34 -66 0 -23 10 -41 34 -66 38 -38 70
-43 116 -19 35 18 50 43 50 85 0 42 -15 67 -50 85 -46 24 -78 19 -116 -19z"/>
      <path d="M180 2855 l0 -165 160 0 160 0 0 -250 0 -250 160 0 160 0 0 415 0
415 -320 0 -320 0 0 -165z"/>
      <path d="M1064 3010 c-18 -4 -48 -17 -67 -29 -140 -87 -87 -310 78 -329 85 -9
155 35 193 121 51 118 -75 264 -204 237z"/>
      <path d="M1432 2995 c-27 -58 -332 -793 -332 -799 0 -3 80 -6 179 -6 l179 0
20 48 c76 178 322 771 322 776 0 3 -80 6 -178 6 l-179 0 -11 -25z"/>
      <path d="M2164 3007 c-3 -8 -4 -45 -2 -83 l3 -69 108 -3 107 -3 0 -330 0 -330
83 3 82 3 3 327 2 327 108 3 107 3 0 80 0 80 -298 3 c-244 2 -298 0 -303 -11z"/>
      <path d="M6131 3013 c0 -4 76 -190 168 -413 l167 -405 71 -3 c62 -2 73 0 82
16 17 31 331 794 331 803 0 5 -40 9 -89 9 l-89 0 -115 -287 c-88 -219 -117
-283 -123 -268 -4 11 -56 139 -115 285 l-108 265 -90 3 c-50 1 -91 -1 -90 -5z"/>
      <path d="M3381 2778 c-48 -8 -115 -44 -148 -80 -12 -12 -34 -50 -49 -82 -49
-107 -31 -249 42 -335 91 -106 221 -129 327 -57 l36 25 3 -27 c3 -26 5 -27 81
-30 l77 -3 0 291 0 290 -80 0 c-79 0 -80 0 -80 -25 l0 -25 -38 25 c-46 30
-113 44 -171 33z m157 -164 c61 -40 83 -154 42 -221 -51 -85 -189 -85 -240 0
-29 46 -27 133 3 178 44 66 131 85 195 43z"/>
      <path d="M5175 2781 c-43 -7 -110 -43 -119 -64 -2 -7 -5 3 -5 21 l-1 32 -80 0
-80 0 0 -290 0 -291 78 3 77 3 5 175 c6 193 15 226 70 255 65 33 131 6 150
-63 5 -20 10 -113 10 -205 l0 -168 78 3 77 3 0 200 c0 214 -6 252 -52 313 -43
57 -126 86 -208 73z"/>
      <path d="M5745 2781 c-79 -19 -144 -77 -183 -161 -36 -77 -35 -178 2 -255 74
-150 256 -208 372 -120 l34 26 0 -36 c0 -46 -20 -85 -62 -120 -30 -25 -37 -27
-108 -23 -64 2 -84 8 -119 31 -24 16 -46 25 -50 20 -55 -65 -74 -97 -64 -112
17 -27 88 -61 162 -76 130 -28 264 7 335 85 60 67 66 105 66 442 l0 299 -77
-3 c-77 -3 -78 -3 -83 -32 l-5 -28 -19 21 c-21 23 -104 52 -145 50 -14 -1 -39
-4 -56 -8z m172 -161 c41 -25 65 -71 65 -126 0 -108 -109 -175 -208 -128 -92
44 -108 174 -29 243 30 26 44 31 88 31 34 0 63 -7 84 -20z"/>
      <path d="M7534 2780 c-97 -14 -172 -66 -219 -151 -27 -49 -30 -63 -30 -144 0
-78 4 -97 27 -141 93 -178 344 -220 492 -83 57 53 60 44 -26 105 l-37 26 -37
-28 c-47 -37 -142 -45 -198 -19 -33 16 -66 58 -66 84 0 7 75 12 213 13 l212 3
-2 67 c-6 184 -143 295 -329 268z m120 -149 c27 -18 55 -55 56 -73 0 -5 -61
-8 -135 -8 -74 0 -135 3 -135 8 0 16 34 54 64 73 41 25 112 25 150 0z"/>
      <path d="M2998 2760 c-20 -11 -44 -30 -52 -42 -16 -22 -16 -22 -16 15 l0 37
-80 0 -80 0 0 -290 0 -291 78 3 77 3 5 175 c4 145 8 181 22 203 26 38 53 49
114 45 l55 -3 -3 80 -3 80 -40 2 c-25 2 -54 -5 -77 -17z"/>
      <path d="M4595 2757 c-3 -7 -4 -136 -3 -287 l3 -275 78 -3 77 -3 0 291 0 290
-75 0 c-52 0 -77 -4 -80 -13z"/>
      <path d="M7020 2480 l0 -291 78 3 77 3 3 288 2 287 -80 0 -80 0 0 -290z"/>
      <path d="M7900 2768 c0 -2 43 -131 94 -288 l94 -285 78 0 79 0 50 152 c28 83
52 154 55 157 3 3 30 -67 59 -155 l53 -160 76 3 77 3 97 288 97 287 -87 0 -87
0 -50 -175 c-27 -96 -52 -175 -55 -175 -3 0 -30 78 -61 173 l-54 172 -62 3
-62 3 -58 -183 -59 -183 -54 183 -53 182 -84 0 c-45 0 -83 -1 -83 -2z"/>
    </g>
  </svg>
);

export default MarketWatch;
