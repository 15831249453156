// core
import React, {useEffect, useState} from 'react';
import {ErrorMessage, Formik, Field, Form} from 'formik';
import {useTranslation} from "react-i18next";

// services
import {sendMail} from "../../../services/mailer";

// components
import Twitter from "../../icons/Twitter";
import Telegram from "../../icons/Telegram";
import YouTube from "../../icons/YouTube";
import Medium from "../../icons/Medium";
import Logo from "../../images/Logo";
import AppButton from "../../atoms/Button";

// styles
import './index.scss'

// data
import {INITIAL_DATA, ModalValidation} from './data';


const TradersModal = ({isActive, onClose}) => {
  const {t} = useTranslation();
  const [isLocalActive, setLocalActive] = useState(false)
  const [modalState, setModalState] = useState('form') //form | message
  const [message, setMessage] = useState(t('modules.influencer.modal.message.successText')) //form | message
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        // @ts-ignore
        document.querySelector('body').classList.add('body--fixed');
        setLocalActive(true)
      }, 100)
    }
  }, [isActive])

  const onCloseHandler = () => {
    setLocalActive(false)
    setTimeout(() => {
      // @ts-ignore
      document.querySelector('body').classList.remove('body--fixed');
    }, 300)
    onClose()
  }

  const onSendHandler = (values) => {
    setIsLoading(true)

    sendMail(values).catch(() => {
      setMessage(t('modules.influencer.modal.message.errorText'))
    }).finally(() => {
      setTimeout(() => {
        setModalState('message')
        setIsLoading(true)
      }, 600);
    });
  }

  return (
    <div className={'influencer-modal' + (isLocalActive ? ' influencer-modal--active' : '')}>
      <div className='influencer-modal__overlay' onClick={onCloseHandler}/>
      <div className='influencer-modal__wrapper'>
        <div className='influencer-modal__content'>
          {modalState === 'form' && (
            <div className='influencer-modal__form'>
              <div className='influencer-modal__icon'>
                <Logo/>
              </div>

              <div className='influencer-modal__title'>
                {t('modules.influencer.modal.title')}
              </div>

              <Formik
                enableReinitialize={true}
                initialValues={INITIAL_DATA}
                onSubmit={onSendHandler}
                validationSchema={ModalValidation}
              >
                {({errors, touched, values}) => (
                  <Form>
                    <div className='influencer-modal__field influencer-modal__field--input'>
                      <label>  {t('modules.influencer.modal.form.inputName.label')}</label>
                      <Field
                        type="text"
                        name="name"
                        autoComplete="chrome-off"
                        className={
                          errors.name &&
                          touched.name &&
                          'influencer-modal__field--error'
                        }
                      />
                      <ErrorMessage name="name" component="div" className="influencer-modal__field-error"/>
                    </div>
                    <div className='influencer-modal__field influencer-modal__field--input'>
                      <label>  {t('modules.influencer.modal.form.inputEmail.label')}</label>
                      <Field
                        type="text"
                        name="email"
                        autoComplete="chrome-off"
                        className={
                          errors.email &&
                          touched.email &&
                          'influencer-modal__field--error'
                        }
                      />
                      <ErrorMessage name="email" component="div" className="influencer-modal__field-error"/>
                    </div>
                    <div className='influencer-modal__field influencer-modal__field--input'>
                      <label>  {t('modules.influencer.modal.form.inputTelegram.label')}</label>
                      <Field
                        type="text"
                        name="telegram"
                        autoComplete="chrome-off"
                        className={
                          errors.telegram &&
                          touched.telegram &&
                          'influencer-modal__field--error'
                        }
                      />
                      <ErrorMessage name="telegram" component="div" className="influencer-modal__field-error"/>
                    </div>

                    <div className='influencer-modal__field influencer-modal__field--input'>
                      <label>  {t('modules.influencer.modal.form.inputSocialMediaLink.label')}</label>
                      <Field
                        type="text"
                        name="socialMediaLink"
                        autoComplete="chrome-off"
                        className={
                          errors.socialMediaLink &&
                          touched.socialMediaLink &&
                          'influencer-modal__field--error'
                        }
                      />
                      <ErrorMessage name="socialMediaLink" component="div" className="influencer-modal__field-error"/>
                    </div>

                    <div className='influencer-modal__field influencer-modal__field--input'>
                      <label>  {t('modules.influencer.modal.form.inputWallet.label')}</label>
                      <Field
                        type="text"
                        name="wallet"
                        autoComplete="chrome-off"
                        className={
                          errors.wallet &&
                          touched.wallet &&
                          'influencer-modal__field--error'
                        }
                      />
                      <ErrorMessage name="wallet" component="div" className="influencer-modal__field-error"/>
                    </div>

                    <ul className='influencer-modal__social'>
                      <li>
                        <a href={'https://twitter.com/MoonWinGame'} target="_blank" rel="noreferrer">
                          <Twitter/>
                        </a>
                      </li>
                      <li>
                        <a href={'https://t.me/moonwingame'} target="_blank" rel="noreferrer">
                          <Telegram/>
                        </a>
                      </li>
                      <li>
                        <a href={'https://www.youtube.com/channel/UCXmMLkk8PI9P5kwL6r-yl_Q'} target="_blank"
                           rel="noreferrer">
                          <YouTube/>
                        </a>
                      </li>
                      <li>
                        <a href={'https://medium.com/@moonwinadv'} target="_blank" rel="noreferrer">
                          <Medium/>
                        </a>
                      </li>
                    </ul>

                    <div className='influencer-modal__actions'>
                      <AppButton
                        disabled={isLoading || Object.keys(errors).length}
                        type={'submit'}
                        loading={isLoading}
                        className="influencer-modal__btn app-button--fill-2">
                        {t('actions.send')}
                      </AppButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {modalState === 'message' && (
            <div className='influencer-modal__message'>
              <div className='influencer-modal__message-text'
                   dangerouslySetInnerHTML={{__html: message}}/>
              <div className='influencer-modal__actions'>
                <AppButton
                  onClick={onCloseHandler}
                  type='submit'
                  className="influencer-modal__btn app-button--fill-2">
                  {t('actions.close')}
                </AppButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TradersModal;



