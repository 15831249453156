import * as Yup from 'yup';

export const INITIAL_DATA = {
  name: '',
  email: '',
  telegram: '',
  socialMediaLink: '',
  wallet: '',
};
export const ModalValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  telegram: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!'),
  socialMediaLink: Yup.string()
    .min(2, 'Too Short!')
    .max(90, 'Too Long!')
});
