const Yahoo = (props) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="970.000000pt" height="400.000000pt" viewBox="0 0 970.000000 400.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
       fill="#ffffff" stroke="none">
      <path d="M4282 2293 l3 -438 108 -3 107 -3 0 186 0 187 29 29 c35 34 59 36 95
8 l26 -20 0 -195 0 -194 111 0 110 0 -3 238 c-3 222 -4 240 -25 278 -30 57
-72 89 -133 104 -62 15 -128 1 -177 -36 l-33 -25 0 160 0 161 -110 0 -110 0 2
-437z"/>
      <path d="M6481 2461 c-61 -149 -111 -272 -111 -276 0 -3 57 -4 127 -3 l127 3
61 150 c34 83 85 205 113 273 l50 122 -127 0 -128 0 -112 -269z"/>
      <path d="M3768 2466 c-186 -50 -276 -290 -178 -474 53 -100 134 -152 239 -154
67 -1 121 15 146 42 24 27 33 25 27 -5 l-4 -25 106 0 106 0 0 305 0 305 -106
0 -106 0 4 -25 c6 -30 -3 -32 -27 -5 -19 21 -103 51 -139 49 -12 0 -42 -6 -68
-13z m172 -199 c34 -17 70 -74 70 -111 0 -17 -9 -45 -20 -63 -66 -109 -230
-65 -230 62 0 95 95 155 180 112z"/>
      <path d="M5174 2470 c-152 -32 -254 -155 -254 -305 0 -88 12 -131 53 -192 58
-88 154 -135 273 -135 88 -1 155 25 218 83 74 67 100 129 101 234 0 98 -29
170 -96 233 -70 68 -197 103 -295 82z m126 -205 c82 -42 80 -179 -4 -219 -45
-21 -74 -20 -113 4 -107 65 -65 227 60 229 15 1 41 -6 57 -14z"/>
      <path d="M5863 2470 c-93 -20 -173 -79 -218 -161 -33 -61 -45 -175 -26 -246
26 -95 119 -188 214 -214 61 -16 157 -14 216 6 69 23 152 99 181 167 75 174
-8 375 -179 434 -55 19 -137 25 -188 14z m126 -204 c85 -44 83 -179 -3 -220
-42 -20 -57 -20 -101 -2 -49 21 -77 70 -72 124 9 88 99 138 176 98z"/>
      <path d="M2880 2393 c15 -38 74 -176 130 -308 l101 -239 -45 -108 c-25 -59
-46 -110 -46 -113 0 -3 49 -5 109 -5 75 0 111 4 118 13 7 9 305 722 340 813 4
11 -16 14 -108 14 l-114 0 -69 -176 c-42 -107 -72 -170 -77 -163 -4 7 -36 86
-71 176 l-63 163 -117 0 -117 0 29 -67z"/>
      <path d="M6353 2100 c-89 -54 -83 -199 10 -243 47 -22 95 -21 135 3 88 54 96
176 16 239 -36 28 -116 29 -161 1z"/>
      <path d="M4414 1776 c-43 -19 -72 -60 -80 -112 -5 -34 -10 -44 -25 -44 -16 0
-19 -8 -19 -50 0 -43 3 -50 20 -50 19 0 20 -7 20 -120 l0 -120 65 0 65 0 0
120 0 120 40 0 40 0 0 50 0 50 -39 0 c-51 0 -59 13 -27 45 19 19 32 24 51 19
24 -6 25 -5 25 44 0 36 -4 52 -16 56 -26 10 -87 6 -120 -8z"/>
      <path d="M4596 1768 c-35 -49 -7 -108 50 -108 41 0 74 29 74 65 0 59 -91 90
-124 43z"/>
      <path d="M4940 1623 c-8 -3 -23 -12 -32 -21 -16 -14 -18 -13 -18 1 0 14 -11
17 -60 17 l-60 0 0 -170 0 -170 60 0 60 0 0 103 c0 88 3 106 18 120 10 10 25
17 33 17 32 0 39 -23 39 -132 l0 -108 65 0 65 0 0 108 c-1 136 -11 180 -50
215 -30 26 -79 35 -120 20z"/>
      <path d="M5232 1613 c-49 -24 -87 -95 -86 -163 0 -101 53 -168 140 -178 43 -5
104 12 104 29 0 5 5 9 10 9 6 0 10 -7 10 -15 0 -12 13 -15 60 -15 l60 0 0 170
0 170 -60 0 c-47 0 -60 -3 -60 -15 0 -8 -4 -15 -10 -15 -5 0 -10 4 -10 8 0 15
-49 32 -88 32 -20 0 -52 -8 -70 -17z m155 -115 c29 -27 29 -65 1 -95 -43 -46
-121 -16 -121 47 0 63 73 92 120 48z"/>
      <path d="M5736 1609 c-21 -17 -26 -18 -26 -5 0 13 -13 16 -65 16 l-65 0 0
-170 0 -170 65 0 65 0 0 104 c0 107 10 136 45 136 35 0 45 -29 45 -136 l0
-104 61 0 60 0 -3 146 -3 146 -33 29 c-41 37 -105 40 -146 8z"/>
      <path d="M6065 1616 c-95 -43 -133 -160 -82 -256 29 -56 90 -90 159 -90 76 0
88 11 88 76 0 51 -1 54 -19 44 -31 -16 -79 -12 -101 10 -26 26 -26 74 1 101
18 18 29 21 70 16 l49 -6 0 49 c0 39 -4 52 -19 60 -27 14 -110 12 -146 -4z"/>
      <path d="M6355 1615 c-103 -56 -134 -179 -70 -276 50 -76 196 -94 272 -33 20
16 35 34 34 39 -2 6 -22 18 -46 27 -39 15 -47 16 -72 3 -15 -8 -33 -15 -39
-15 -15 0 -64 39 -64 51 0 5 53 9 120 9 l120 0 0 40 c0 52 -31 112 -72 141
-42 30 -139 37 -183 14z m120 -95 c10 -11 16 -23 13 -26 -3 -3 -32 -4 -64 -2
l-59 3 23 23 c27 27 63 28 87 2z"/>
      <path d="M4590 1450 l0 -170 60 0 60 0 0 170 0 170 -60 0 -60 0 0 -170z"/>
    </g>
  </svg>
);

export default Yahoo;
