// core

// components
import Bloomberg from "../../../components/icons/publications/Bloomberg";
import MarketWatch from "../../../components/icons/publications/MarketWatch";
import TradingView from "../../../components/icons/publications/TradingView";
import Yahoo from "../../../components/icons/publications/Yahoo";

// styles
import './index.scss'

const HomePublications = () => {
  return (
    <div className="home-publications">
      <div className="home-publications__grid">
        <div className="home-publications__card">
          <a href={'https://finance.yahoo.com/news/moonwin-launches-revolutionary-crypto-gaming-151500813.html'}
             target='_blank'
             className="home-publications__card-link">
            <Yahoo/>
          </a>
        </div>

        <div className="home-publications__card">
          <a href={'http://marketwatch.com/press-release/moonwin-launches-revolutionary-crypto-gaming-platform-redefining-the-industry-2023-04-03?mod=search_headline'}
             target='_blank'
             className="home-publications__card-link">
            <MarketWatch/>
          </a>
        </div>

        <div className="home-publications__card">
          <a href={'https://www.tradingview.com/news/reuters.com,2023-03-31:newsml_GNX5rbs3Q:0/'}
             target='_blank'
             className="home-publications__card-link">
            <TradingView/>
          </a>
        </div>

        <div className="home-publications__card">
          <a href={'https://www.bloomberg.com/press-releases/2023-03-31/moonwin-launches-revolutionary-crypto-gaming-platform-redefining-the-industry'}
             target='_blank'
             className="home-publications__card-link">
            <Bloomberg/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomePublications;
