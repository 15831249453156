const MarketWatch = (props) => (
  <svg viewBox="0 0 104.96000000000001 15.064114563836132" xmlns="http://www.w3.org/2000/svg" width="2500" height="359">
    <path d="M24.58 10.48a2.64 2.64 0 0 1-2.51 2.17 1.71 1.71 0 0 1-1.71-2.13 2.62 2.62 0 0 1 2.49-2.17 1.68 1.68 0 0 1 1.73 2.13m.8-4.2l-.18.88A2.27 2.27 0 0 0 22.91 6a5.34 5.34 0 0 0-5.06 4.5c-.44 2.41.72 4.52 3.39 4.52a3.34 3.34 0 0 0 2.69-1.2l-.16.9h2.49l1.57-8.41h-2.45zM29.28 6.29h2.35l-.16.88A2.63 2.63 0 0 1 33.83 6l-.46 2.53c-1.18 0-2 .26-2.27 1.55l-.86 4.62h-2.53z"
          fill="#ffffff"/>
    <path d="M0 0h104.83v15H0z" fill="none"/>
    <path d="M35.52 2.99L33.35 14.7h2.53l.75-4.06h.04l1.62 4.06h2.85l-2.2-4.72 3.85-3.69h-2.97l-2.91 3.05h-.04l1.18-6.35z"
          fill="#ffffff"/>
    <path d="M44.28 9.56a2.36 2.36 0 0 1 2.19-1.44 1.46 1.46 0 0 1 1.63 1.44zM46.83 6a5.65 5.65 0 0 0-5.24 4.54A3.61 3.61 0 0 0 45.22 15a4.71 4.71 0 0 0 2.53-.76 6.35 6.35 0 0 0 2.11-2.15h-2.57a2.18 2.18 0 0 1-1.69.78 1.5 1.5 0 0 1-1.67-1.59h6.31a3 3 0 0 0 .18-.66C50.9 8 49.4 6 46.83 6"
          fill="#ffffff"/>
    <path d="M0 0h104.83v15H0z" fill="none"/>
    <path d="M14.5 2.99l-4.44 6.67h-.04V2.99H7.87L0 14.7h3.05l4.42-6.67h.04v6.67h2.17l4.42-6.67h.04v6.67h2.53V2.99zM56.81 6.29h-1.59l.46-2.49h-2.21l-.18 1a1.74 1.74 0 0 1-1.81 1.53l-.38 1.86h1.25l-1.21 6.51h2.51l1.21-6.51h1.61z"
          fill="#ffffff"/>
    <path d="M98.13 3h2.51l-.76 4.17A2.63 2.63 0 0 1 102.29 6a2.57 2.57 0 0 1 2.13.94c.54.82.48 1.57.2 3.07l-.86 4.7h-2.51l.84-4.58c.08-.4.32-1.77-1-1.77A1.77 1.77 0 0 0 99.34 10l-.87 4.7H96zM87.55 6.29H86l.43-2.49h-2.21l-.22.96a1.74 1.74 0 0 1-1.81 1.53l-.34 1.9h1.24l-1.2 6.51h2.51l1.2-6.51h1.61zm-12.11 6.36A2.64 2.64 0 0 0 78 10.48a1.68 1.68 0 0 0-1.73-2.13 2.62 2.62 0 0 0-2.49 2.17 1.71 1.71 0 0 0 1.71 2.13m4.24 2h-2.54l.16-.9A3.34 3.34 0 0 1 74.62 15c-2.67 0-3.84-2.11-3.39-4.52A5.34 5.34 0 0 1 76.28 6a2.27 2.27 0 0 1 2.25 1.18l.18-.88h2.49zM74.9 0L70 3h1.39l-4.44 6.66V3h-2.21L60.3 9.66V3h-2.57v11.7h2.19L64.36 8v6.7h2.17L74.42 3h1.24zm20.86 11.51A5.7 5.7 0 0 1 90.7 15a3.62 3.62 0 0 1-3.7-4.54A5.56 5.56 0 0 1 92.31 6a3.56 3.56 0 0 1 3.79 3.54h-2.55a1.49 1.49 0 0 0-1.61-1.19 2.45 2.45 0 0 0-2.39 2.13 1.63 1.63 0 0 0 1.61 2.15 2.4 2.4 0 0 0 2-1.12h2.57z"
          fill="#4db84d"/>
  </svg>

);

export default MarketWatch;
